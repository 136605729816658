/* That's How I align center the Hole Component */
.portfolio__content {
  display: flex;
  flex-direction: column;
  align-self: center;
}
/* End */

a {
  color: unset;
  text-decoration: none;
}

.portfolio__head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.portfolio__title {
  height: 100px;
  width: auto;

  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;

  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.portfolio__title > h1 {
  font-family: "Bahnschrift";
  font-weight: 200;
  color: #fff;
}

.portfolio__subtitle {
  margin: 0.5rem auto 2rem auto;
  color: #9c9c9e;
  font-family: "Microsoft Yi Baiti";
  font-weight: 200;
  font-size: 24px;
}

.portfolio__works {
  height: 100%;
  flex-direction: column;
  display: flex;
  align-content: center;
  align-items: center;
}

.work {
  margin-bottom: 1.4rem;
  display: flex;
  height: 5rem;
  width: 40rem;
  padding: 0;

  border-top-left-radius: 10px;

  background-color: #14213d;
}

.work:hover > .portfolio__img {
  filter: brightness(.4);
  transition: 1s ease-in-out;
}

.portfolio__dots {
  margin-left: 0.5rem;
  height: 100%;
  width: 10rem;

  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.portfolio__img {
  height: 100%;
  flex: 20rem 0;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.portfolio__number {
  flex: 7% 0;

  color: #fff;
  font-family: "Bahnschrift";
  font-weight: 300;

  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.portfolio__name {
  flex: 30% 0;

  font-family: "Bahnschrift";
  font-weight: 300;
  font-size: larger;
  color: #fff;

  display: flex;
  align-content: center;
  align-items: center;
  justify-content: start;
}

@media screen and (max-width: 1007px) {
  .work {
    flex-direction: column;
    width: 70vw;
    height: 7rem;
  }

  .work > div {
    flex: 100% !important;
  }

  .portfolio__dots {
      display: none;
  }

  .portfolio__number {
      padding: 10px 0;
  }

  .portfolio__name {
      padding-bottom: 10px;
      justify-content: center;
  }
}

/* x < 640px */
/* 641px < x < 1007px */
/* 1008px < x */
