.contact__content {
    display: flex;
    margin-top: 1.5rem;
    min-width: 55rem;
}

.contact__credentials, .contact__form {
    flex: 50%;
}

.contact__credentials {
    align-self: center;
}

.contact_cred {
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
}

.contact_cred > h4, form > h4 {
    color: #fff;
    font-family: "Bahnschrift";
    font-weight: 300;
}

.contact_cred > p {
    margin-top: .2rem;
    margin-left: 2rem;
    font-size: 18px;
    font-family: "Bahnschrift";
    font-weight: 200;
    color: rgb(177, 174, 174);
}

.contact__form {
    display: flex;
    align-items: flex-start;
}

.contact__form > form {
    display: flex;
    flex-direction: column;
}

.userName, .email, .subject, .message, .contact__form > form > button {
    margin-top: 1.4rem;
}

.userName, .email, .subject, .message {
    color: #000;
    font-family: "Bahnschrift";
    background-color: #fff;
    border-radius: 10px;
    padding: 5px 15px;
}

@media screen and (max-width: 641px) {
    .portfolio__title {
        justify-content: unset !important;
    }
    
    .contact__content {
        flex-direction: column;
        min-width: unset;
    }

    .contact_cred > h4, form > h4 {
        font-size: x-small;
    }

    .contact_cred > p {
        font-size: xx-small;
    }

    .contact_cred {
        padding-left: .2rem;
    }

    .contact__credentials {
        align-self: flex-start;
    }

    .userName, .email, .subject, .message, .contact__form > form > button {
        margin-top: .5rem;
    }

    .userName, .email, .subject, .message {
        padding: 5px 10px;
        font-family: Arial, Helvetica, sans-serif !important;
        font-size: 15px !important;
    }

    .contact.component > footer {
        margin-top: 2vh;
    }
}


@media screen and (min-width: 641px) and (max-width: 1007px) {
    .contact__content {
        min-width: 70vw;
    }
    
    .contact__credentials {
        flex: 40% !important;
    }
}