footer {
  margin-top: 5vh;
  display: flex;
  justify-content: space-between;
}

.footer__links > a {
  margin-right: 10px;
  font-size: 30px;
  color: #fff;
}

footer > a:hover {
  text-decoration: none;
}

.MuiTypography-root {
  font-size: 30px;
  margin-right: 15px !important;
  color: #fff !important;
}

.arrow_link  {
  padding: 10px;
  background-color: #353d53;
  color: #fff;
  border-radius: 50%;
  height: fit-content;

  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.footer__arrow:hover {
  cursor: pointer;
}

.MuiSvgIcon-root {
  color: #fff;
}
