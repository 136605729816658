.lines {
    margin-right: 2rem;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
}

.upper__line {
    border-radius: 15px;
    flex: 40%;
    background-color: #ABA7A7;
    width: .2rem;
}

.lines__pageNumber {
    padding: 4rem 0;
    width: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
}

.circleIndex {
    padding: 10px 10px 7px;
    border: 1px solid #ABA7A7;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    font-family: "Bahnschrift";
}

.bottom__line {
    border-radius: 15px;
    flex: 40%;
    background-color: #ABA7A7;
    width: .2rem;
}