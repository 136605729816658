nav {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
}

h6 {
    color: #fff;

    font-family: "Microsoft Yi Baiti";
    font-size: larger;
    font-weight: 200;
}