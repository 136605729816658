.home__intro {
    position: relative;
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
    width: 180px;
    height: 4rem;
}

.home__intro > h6 {
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 0;
    left: 0;

    font: 17px "Bahnschrift";
    font-weight: 300;
}

.home__main {
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
}

.home__content > h1 {
    color: #fff;
    font-family: "Bahnschrift";
    font-weight: 400;
}

.home__content > h4 {
    margin-bottom: 2rem;
    color: #ABA7A7;
    font-size: 22px;
    font-family: "Microsoft Yi Baiti";
    font-weight: 200;
}

.home__content > p {
    color: #fff;
    font-family: "Microsoft Yi Baiti";
    font-size: 18px;
    width: 60%;
}

.home__profile {
    flex: 50%;
    height: 300px;
    object-fit: cover;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    border-radius: 50%;
}
 
/* x < 640px */

@media screen and (max-width: 1007px) {    
    .app {
        padding: .4rem 1.2rem;
    }

    .home__main {
        flex-direction: column;
    }

    .home__content > p {
        text-align: right;
        margin-left: auto;
    }
}

/* 641px < x < 1007px */
/* 1008px < x */
