.about__content {
  display: flex;
}

.about__sections {
  flex: 30%;
  align-self: center;
}

.about__section {
  display: flex;
  align-items: center;
  margin-right: 5rem;
}

.about__section:hover {
  cursor: pointer;
}

.about__section > h6,
.about__section > p {
  font-family: "Microsoft Yi Baiti";
  color: #8d8d8d;
}

.about__section > p {
  margin-right: 5px;
  margin-left: 50px;
  word-spacing: 0.02%;
  width: 90px;
  padding-left: 10px;
  border-left: 2px solid #8d8d8d;
}

.about__section__content {
  margin-left: 1rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.about__section__content > div > h4 {
  margin-bottom: 2rem;
  color: #fff;
  font-family: "Bahnschrift";
  font-weight: 400;
}

.briefly > p {
  color: #fff;
  width: 30rem;

  font-family: "Microsoft Yi Baiti";
  font-weight: 300;
  font-size: 22px;
}

.skills_content {
  display: flex;
  flex-direction: column;
}

.skill {
  display: flex;
  margin-bottom: 1.1rem;
}

.skill > h6 {
  font-family: "Microsoft Yi Baiti";
  font-weight: 200;
  font-size: 15px !important;
  width: fit-content;
}

.skill__icons {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  justify-items: center;
}

.skill__icons > img {
  max-height: 1rem;
}

.skill:first-child > h6 {
  margin-top: -7px;
  font-size: 1rem;
}

.material,
.redux {
  margin-left: 1rem;
}

.strategies {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: start;
}

.strategy {
  display: flex;
  margin-bottom: 1rem;
}

.strategy > h5 {
  margin-left: 15px;
  font-family: "Microsoft Yi Baiti";
  font-size: 25px;
  font-weight: 400;
  color: #fff;
}

@media screen and (max-width: 1007px) {
  .app {
    padding: 0.4rem 1.2rem;
  }

  .lines {
    margin-right: 0.5rem;
  }

  .component.about {
    width: 95vw;
  }

  .about__sections {
    align-self: flex-start;
  }
}

/* x < 640px */
@media screen and (max-width: 640px) {
  .portfolio__head {
    position: absolute;
    top: 0;
    right: 0;
  }

  .portfolio__head > .portfolio__subtitle {
    margin: 0.5rem auto 0.5rem;
  }

  .portfolio__title {
    justify-content: unset !important;
    background-position: left !important;
    height: 50px !important;
  }

  .portfolio__title > h1 {
    font-size: 16px;
  }

  .portfolio__head > .portfolio__subtitle {
    margin: 0.5rem 0 0.5rem 0;
  }

  .portfolio__subtitle > h4 {
    margin-top: -0.5rem;
    font-size: 15px;
  }

  .app {
    padding: 0.4rem 0.1rem;
  }
  .about__content {
    margin-left: -0.5rem;
    margin-top: -0.5rem;
    flex-direction: column;
  }
  .about__sections {
    display: flex;
  }
  .about__section {
    margin-right: 0;
  }

  .about__section > h6 {
    display: none;
  }

  .about__section > p {
    margin-left: 5px;
    width: max-content;
    font-size: small;
    color: #fff;
  }

  .about__section__content {
    overflow-y: scroll;
  }

  .about__section__content > div > h4 {
    margin-top: 1rem;
    margin-bottom: 1rem !important;
    font-size: 15px;
  }

  .briefly > p {
    width: 70vw;
    font-size: 18px;
  }

  .strategy > h5 {
    margin-left: 10px;
    font-size: 21px;
  }

  .skills > h4 {
    margin: 0 !important;
    max-width: 55%;
  }

  .skill {
    flex-direction: column;
  }

  .skill > h6 {
    margin-bottom: 0.5rem;
    max-width: 60%;
  }

  .skill__icons > h6 {
    font-size: 1rem;
    margin-left: 0.4rem;
  }

  .MuiSvgIcon-root {
    font-size: large !important;
  }

  .html {
    max-width: 40vw;
  }

  main {
    margin-top: 2vh;
  }

  footer {
    margin-top: 0.1rem !important;
  }
}

/* 641px < x < 1007px */
@media screen and (min-width: 641px) and (max-width: 1007px) {
  .portfolio__title {
    justify-content: center !important;
  }

  .about__content {
    flex-direction: column;
  }

  .skill > h6 {
    width: 12rem;
  }

  .about__sections {
    margin-bottom: 2rem;
    margin-left: 1rem;
  }

  .about__section {
    align-items: baseline;
  }

  .about__section > p {
    margin-bottom: unset;
    margin-left: 2rem;
  }

  .about__section__content > div > h4 {
    margin-bottom: 0.5rem;
  }
}
/* 1008px < x */
