h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

.app {
  height: 100vh;
  padding: 1rem 4rem;
  display: flex;
  justify-content: center;
  
  background: #0f2027;
}

main {
  display: flex;
  margin-top: 4vh;
  align-content: center;
  justify-content: center;
}

.component {
  display: flex;
  align-content: center;
  align-items: center;
}
